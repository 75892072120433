import { RemoteConfig } from '~/pages/api/remoteConfig'
import useSWR from 'swr'

// @ts-ignore
const fetcher = (...args) => fetch(...args).then(res => res.json())

export default () => {
    const { data, error, isLoading } = useSWR('/api/config', fetcher)

    return !error && !isLoading ? data as RemoteConfig : {
        isDefault: true,
        pu: false,
        br: false,
        frf: 0,
        ignoreFRF: false,
    } as RemoteConfig
}
