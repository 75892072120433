import { NextResponse, userAgent } from 'next/server'
import type { NextRequest } from 'next/server'
import { v4 as uuidv4 } from 'uuid'
import { IS_DEV, isBackRedirectEnabled } from '~/lib/constants'
import { COOKIE_BACK_REDIRECTION, COOKIE_USER_ID } from '~/lib/cookies'

const REGEX_SELF = /^https:\/\/(www\.)?reviewhub.ai/i
const REGEX_LOCALHOST = /^http:\/\/localhost/i
const REGEX_DEFAULT_REFERRER = /^about:client/i

export const HEADER_REQUEST_ID = 'rh-request-id'
export const HEADER_IS_BOT = 'rh-is-bot'
export const HEADER_DEVICE_TYPE = 'rh-device-type'

// This function can be marked `async` if using `await` inside
export function middleware(req: NextRequest) {
    const requestId = uuidv4()
    const referer = req.headers.get('referer') // req.referrer
    const { device, isBot } = userAgent(req)
    const deviceType = device.type // console, mobile, tablet, smarttv, wearable, embedded, or undefined

    // copy of existing headers we can append our own to
    const requestHeaders = new Headers(req.headers)

    if (req.nextUrl.pathname.startsWith('/api') || req.nextUrl.pathname.startsWith('/link')) {
        // append a request id to all api requests
        requestHeaders.set(HEADER_REQUEST_ID, requestId)
        requestHeaders.set(HEADER_IS_BOT, String(isBot))
        requestHeaders.set(HEADER_DEVICE_TYPE, deviceType)
    }

    const response = NextResponse.next({
        request: {
            headers: requestHeaders,
        },
    })

    // Set User Cookie if we dont have one for tracking purposes
    if (!req.cookies.has(COOKIE_USER_ID)) {
        response.cookies.set({
            name: COOKIE_USER_ID,
            value: uuidv4(),
            expires: new Date(2147483647000),
            httpOnly: true,
            sameSite: 'strict',
            secure: !IS_DEV,
        })
    }

    if (!req.nextUrl.pathname.startsWith('/api')) {
        // must be outside referrer
        const shouldSetupBackRedirection = isBackRedirectEnabled && referer && !REGEX_SELF.test(referer) && !REGEX_DEFAULT_REFERRER.test(referer) // && !REGEX_LOCALHOST.test(referer)
        if (shouldSetupBackRedirection) {
            response.cookies.set({
                name: COOKIE_BACK_REDIRECTION,
                value: String(true),
                maxAge: 10,
                httpOnly: false,
                sameSite: 'strict',
                secure: !IS_DEV,
            })
        }
    }

    return response
}

export const config = {
    matcher: [
        /*
         * Match all request paths except for the ones starting with:
         * - images
         * - _next/data
         * - _next/static (static files)
         * - favicon.ico (favicon file)
         */
        '/((?!images|_next/image|_next/data|_next/static|favicon.ico|manifest.json|fonts).*)',
    ]
}