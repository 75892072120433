// Screen width when desktop styles should be applied
export const DESKTOP_BREAKPOINT = 768

export const IS_DEV = process.env.NODE_ENV === 'development'
export const APP_TITLE = 'ReviewHub'
export const APP_TITLE_LOWER = 'ReviewHub'
export const DOMAIN = 'www.reviewhub.ai'
export const EMAIL = 'hello@reviewhub.ai'
export const MAX_CONTENT_WIDTH = 1300
export const DEFAULT_ZONE_NAME = 'America/New_York'
export const DEFAULT_LOCALE = 'en-US'

export const AFFILIATE_CODE_AMAZON = '?tag=reviewhub0f0-20'

export const SM_MAX_WIDTH_BREAKPOINT = '(max-width: 600px)'
export const MD_MAX_WIDTH_BREAKPOINT = '(max-width: 900px)'
export const LG_MAX_WIDTH_BREAKPOINT = '(max-width: 1200px)'
export const XL_MAX_WIDTH_BREAKPOINT = '(max-width: 1536px)'

export const XS_MIN_WIDTH_BREAKPOINT = '(min-width: 0px)'
export const SM_MIN_WIDTH_BREAKPOINT = '(min-width: 600px)'
export const MD_MIN_WIDTH_BREAKPOINT = '(min-width: 900px)'
export const LG_MIN_WIDTH_BREAKPOINT = '(min-width: 1200px)'
export const XL_MIN_WIDTH_BREAKPOINT = '(min-width: 1536px)'

// Please make sure you've renamed an appropriate page name in
// the `./pages` directory when update the routes
export const ROUTES = {
    home: '/',
    // ACCOUNT: {
    //   SIGN_IN: "/account/sign-in",
    //   FORGOT: "/account/forgot-password",
    // },
    review: '/review',
    privacy: '/privacy',
    termsOfService: '/terms',
    outlink: '/link',
}

export const isAffiliateOutRedirectLinkEnabled = true
export const isPopunderEnabled = false
export const isBackRedirectEnabled = false