import { HEADER_DEVICE_TYPE, HEADER_IS_BOT } from '~/middleware'
import { IS_DEV, isPopunderEnabled } from '~/lib/constants'
import { NextApiRequest, NextApiResponse } from 'next'
import { COOKIE_BACK_REDIRECTION } from '~/lib/cookies'

export const DEFAULT_FAKE_REDIRECTION_FREQUENCY = 1800000 // 1800000=30 mins, or 15000=15 secs for debug

export interface RemoteConfig {
    isDefault: boolean // whether default client side config or remote values
    ignoreFRF: boolean // option to ignore fake redirect frequency, mostly for debug
    pu: boolean
    br: boolean // redirect on back press from landing page feature enabled
    frf: number // fake redirection frequency in millis, popunder or back redirects
}

export default async function handler(req: NextApiRequest, res: NextApiResponse<RemoteConfig>) {
    if (req.method !== 'GET') {
        res.status(404).end()
        return
    }

    const hasBackRedirectCookie = req.cookies[COOKIE_BACK_REDIRECTION] === 'true'
    const isBot = req.headers[HEADER_IS_BOT] === 'true'
    const deviceType = req.headers[HEADER_DEVICE_TYPE] as string

    // popunder shouldnt be on mobile since that may trigger the amazon app to open, completely interrupting user experience.
    const deviceTypesNotToPopunder = [ 'mobile', 'tablet', 'smarttv', 'wearable' ]
    const canPopUnder = !isBot && deviceTypesNotToPopunder.indexOf(deviceType) < 0

    // back redirect should work on mobile, popunder should be disabled
    res.json({
        isDefault: false,
        pu: canPopUnder && isPopunderEnabled && !IS_DEV,
        br: hasBackRedirectCookie,
        frf: DEFAULT_FAKE_REDIRECTION_FREQUENCY,
        ignoreFRF: false,
    })
}
