import React, { useEffect, useState } from 'react'
import seo from '~/lib/seo'
import * as gtag from '../lib/gtag'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Script from 'next/script'
import Page from 'components/Page'
import cookieCutter from 'cookie-cutter'

import 'styles/global.scss'
// import 'aos/dist/aos.css'
import { DOMAIN, ROUTES } from '~/lib/constants'
import useRemoteConfig from '~/lib/hooks/useRemoteConfig'
import { COOKIE_BACK_REDIRECTION, COOKIE_LAST_CATEGORY, COOKIE_LAST_REVIEW_SLUG, STORAGE_FAKE_REDIRECT_FREQUENCY } from '~/lib/cookies'
import { canFakeRedirect, isStorageAvailable, updateLastLinkRedirectionTimeInStorage } from '~/lib/helpers'
import { NavigationProps } from '~/lib/contexts/AppContexts'

const REGEX_INITIAL_HASH = /#initial/i
const REGEX_ALL_PAGES = /^\/privacy|terms|datadump|theme/i
const REGEX_IS_INDEX_PAGE = /^\/$/i

export default function Application({ Component, pageProps }) {
    const router = useRouter()
    const remoteConfig = useRemoteConfig()
    const [ initialLandingPathname, setInitialLandingPathname ] = useState(undefined)
    const [ setupForBackRedirect, setSetupForBackRedirect ] = useState(false)
    const navigationProps = pageProps.navigationProps as NavigationProps

    // map of cat slugs to full breadcrumbed name for cookie and redirection purposes
    const categoryNavSlugsToFullNameMap = {}
    navigationProps.categoryNavData.forEach(topCatObj => {
        categoryNavSlugsToFullNameMap[topCatObj.slug] = topCatObj.name
        topCatObj.expandedItems.forEach(subcatObj => {
            categoryNavSlugsToFullNameMap[subcatObj.slug] = `${topCatObj.name}/${subcatObj.name}`
        })
    })

    /**
     * Update cookies with pathname
     * @param pathname ex: /review/best-abc or /tech-electronics/headphones
     */
    function updateNavigationCookies(pathname: string) {
        const lowercasePathname = pathname.toLowerCase()
        const reviewMatch = lowercasePathname.match(/^\/review\/(.+)/i)
        if (reviewMatch) {
            const slug = reviewMatch[1].replace('/', '') // remove any trailing slashes
            cookieCutter.set(COOKIE_LAST_REVIEW_SLUG, slug, {
                expires: new Date(2147483647000),
                path: '/',
            })
        } else {
            const catName = categoryNavSlugsToFullNameMap[lowercasePathname]
            if (catName) {
                cookieCutter.set(COOKIE_LAST_CATEGORY, catName, {
                    expires: new Date(2147483647000),
                    path: '/',
                })
            }
        }
    }

    useEffect(() => {
        // initial setup with router history replacing to #initial
        if (canFakeRedirect(true)) {
            setSetupForBackRedirect(cookieCutter.get(COOKIE_BACK_REDIRECTION) === 'true')
        }
    }, [])

    useEffect(() => {
        if (remoteConfig.isDefault) {
            return // we only want to override values
        }

        // Store certain remote configs to localStorage
        if (isStorageAvailable('localStorage')) {
            localStorage.setItem(STORAGE_FAKE_REDIRECT_FREQUENCY, String(remoteConfig.frf))
        }
    }, [ remoteConfig ])

    useEffect(() => {
        const handleRouteChange = (url) => {
            gtag.pageview(url)
            updateNavigationCookies(url)
        }
        const handleRouteError = (err, url, { shallow }) => {
            console.log('Navigating to: ' + 'url: ' + url, { cancelled: err.cancelled })
        }

        router.events.on('routeChangeComplete', handleRouteChange)
        router.events.on('routeChangeError', handleRouteError)

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
            router.events.off('routeChangeError', handleRouteError)
        }
    }, [ router.events ])

    // Also handles navigating away completely, on mount window.onbeforeunload = browserTabcloseHandler, set null on unmount
    // const browserTabcloseHandler = e => {
    //     e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    //     // Chrome requires returnValue to be set
    //     e.returnValue = "";
    // };

    // Needed for back navigation redirect. Order of exec: 1
    useEffect(() => {
        // just a precaution, might not be necessary
        if (!router.isReady) {
            return
        }

        // Store the pathname user initially landed on. This is needed for the back navigation redirect. Order of exec: 2
        if (initialLandingPathname === undefined && router.isReady) {
            // we want the trimmed pathname without possible query or hash
            const pathname = new URL(`https://${DOMAIN}${router.asPath}`).pathname
            setInitialLandingPathname(pathname)
            updateNavigationCookies(pathname)
        }

        router.beforePopState(({ url, as, options }) => {
            if (REGEX_INITIAL_HASH.test(url)) {
                // feature must be enabled, and we should be able to do it time-wise
                if (remoteConfig.br && canFakeRedirect(false, remoteConfig.ignoreFRF)) {
                    updateLastLinkRedirectionTimeInStorage()
                    window.location.href = `${ROUTES.outlink}/br`
                    return false // dont let nextjs handle this one further
                } else {
                    router.back() // feature disabled, skip this special path
                }
            }
            return true
        })

        return () => {
            router.beforePopState(() => true)
        }
    }, [ router.isReady, remoteConfig ])

    // Needed for back navigation redirect.  Order of exec: 3
    useEffect(() => {
        if (initialLandingPathname === undefined || !setupForBackRedirect || initialLandingPathname === '/terms' || initialLandingPathname === '/privacy') {
            return
        }

        const handleRouteHashChange = url => {
            if (REGEX_INITIAL_HASH.test(url)) {
                // reset back to landing page pathname
                router.push(initialLandingPathname, undefined, { shallow: true })
            }
        }
        router.events.on('hashChangeComplete', handleRouteHashChange)
        router.replace('#initial', '#initial', { shallow: true })

        return () => {
            router.events.off('hashChangeComplete', handleRouteHashChange)
        }
    }, [ initialLandingPathname ])

    return (
        <React.Fragment>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <title key={'title'}>{seo.title}</title>

                {/*SEO*/}

                <meta name="theme-color" content="#3C78F0"/>
                <meta name="description" key={'description'} content={seo.description}/>
                {seo.siteName ? <meta property="og:site_name" key={'site_name'} content={seo.siteName}/> : ''}
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" key={'type'} content={seo.type}/>
                <meta property="og:title" key={'og_title'} content={seo.title}/>
                <meta property="og:description" key={'og_description'} content={seo.description}/>
                <meta property="og:url" key={'og_url'} content={seo.homeUrl}/>
                <meta property="og:image" key={'og_image'} content={seo.homeUrl + seo.image}/>
                {seo.imageAlt ? <meta property="og:image:alt" content={seo.imageAlt}/> : ''}

                <meta name="twitter:image" key={'tw_image'} content={seo.homeUrl + seo.image}/>
                {seo.imageAlt ? <meta property="twitter:image:alt" content={seo.imageAlt}/> : ''}
                <meta name="twitter:card" key={'tw_card'} content={seo.twitterCard}/>
                {/*<meta name="twitter:site" key={'tw_site'} content={seo.twitter}/>*/}
                <meta name="twitter:title" key={'tw_title'} content={seo.title}/>
                <meta name="twitter:description" key={'tw_description'} content={seo.description}/>
                {/*END OF SEO*/}
            </Head>
            <Page>
                {/* Global Site Tag (gtag.js) - Google Analytics */}
                <Script
                    strategy="afterInteractive"
                    src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
                />
                <Script
                    id="gtag-init"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', '${gtag.GA_TRACKING_ID}', {
                              page_path: window.location.pathname,
                            });
                        `,
                    }}
                />

                <Component {...pageProps} />
            </Page>
        </React.Fragment>
    )
}
