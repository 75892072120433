import { DOMAIN } from '~/lib/constants'

export default {
    shouldIndex: true,
    type: 'website',
    siteName: 'ReviewHub: Intelligent product reviews and top recommendations powered by AI', // OG tag, if part of a larger web site, the name which should be displayed for the overall site. e.g., "IMDb".
    title: `ReviewHub: Intelligent product reviews and top recommendations powered by AI`,
    description: `ReviewHub is a product recommendation service for finding the highest quality products and best deals that match your needs.`,
    homeUrl: `https://${DOMAIN}`,
    image: '/images/reviewhub-logo-blue.svg',
    imageAlt: null,
    // twitter: '@reviewhub',
    twitterCard: 'summary',
}
