import { responsiveFontSizes } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import shadows from './shadows'
import { light, dark } from './palette'
// import ChronicaProHeavy from './fonts/ChronicaPro-Heavy.woff2'
const chronicaProHeavy = '/fonts/ChronicaPro-Heavy.woff2'

const getTheme = (mode, themeToggler) =>
  responsiveFontSizes(
    createTheme({
      palette: mode === 'light' ? light : dark,
      shadows: shadows(mode),
      typography: {
        htmlFontSize: 16,
        fontFamily: '"Averta", "Helvetica Neue", Arial, sans-serif',
        button: {
          textTransform: 'none',
          fontWeight: 'medium',
        },
        h1: {
          fontSize: '3rem', // 48px
          fontFamily: '"Brandon Grotesque Medium", "Helvetica Neue", Arial, sans-serif',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        h2: {
          fontSize: '2.5rem', // 40px
          fontFamily: '"Brandon Grotesque Medium", "Helvetica Neue", Arial, sans-serif',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        h3: {
          fontSize: '2rem', // 32px
          fontFamily: '"Brandon Grotesque Medium", "Helvetica Neue", Arial, sans-serif',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        h4: {
          fontSize: '1.6rem', // 24px
          fontFamily: '"Brandon Grotesque Medium", "Helvetica Neue", Arial, sans-serif',
          // fontFamily: '"Brandon Grotesque", "Helvetica Neue", Arial, sans-serif',
          fontWeight: 'normal',
          fontStyle: 'normal'
        },
        h5: {
          fontSize: '1.25rem', // 20px
          fontFamily: '"Brandon Grotesque Medium", "Helvetica Neue", Arial, sans-serif',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        h6: {
          fontSize: '1.10rem',
          fontFamily: '"Brandon Grotesque Medium", "Helvetica Neue", Arial, sans-serif',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        subtitle1: {
          fontSize: '1.25rem', // 20px
          lineHeight: 1.3,
        },
        subtitle2: {
          fontSize: '1rem', // 16px
          lineHeight: 1.3,
        },
        subtitle3: {
          fontSize: '0.875rem', // 14px
          lineHeight: 1,
        },
        body20: {
          fontSize: '1.25rem', // 20px
          lineHeight: 1.5,
        },
        tight16: {
          fontSize: '1rem', // 16px
          lineHeight: 1,
          letterSpacing: 0,
        },
        tight14: {
          fontSize: '0.875rem', // 14px
          lineHeight: 1,
          letterSpacing: 0,
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: `
            @font-face {
                font-family: 'Averta';
                src: url('/fonts/Averta/Averta-Semibold.woff2') format('woff2'),
                url('/fonts/Averta/Averta-Semibold.woff') format('woff');
                font-weight: 600;
                font-style: normal;
                font-display: swap;
            }
            
            @font-face {
                font-family: 'Averta';
                src: url('/fonts/Averta/Averta-Regular.woff2') format('woff2'),
                url('/fonts/Averta/Averta-Regular.woff') format('woff');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
            }
            
            @font-face {
                font-family: 'Averta';
                src: url('/fonts/Averta/Averta-Bold.woff2') format('woff2'),
                url('/fonts/Averta/Averta-Bold.woff') format('woff');
                font-weight: bold;
                font-style: normal;
                font-display: swap;
            }
            
            // @font-face {
            //   font-family: 'ChronicaPro';
            //   font-style: heavy;
            //   font-display: swap;
            //   font-weight: 900;
            //   src: local('ChronicaPro'), local('ChronicaPro-Heavy'), url(${chronicaProHeavy}) format('woff2');
            //   unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            // }
          `,
        },
        MuiImageListItemBar: {
          styleOverrides: {
            title: {
              fontSize: '0.875rem',
              whiteSpace: 'initial',
              lineHeight: 1.3,
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 400,
              borderRadius: 5,
              paddingTop: 10,
              paddingBottom: 10,
            },
            containedSecondary: mode === 'light' ? { color: 'white' } : {},
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
            input: {
              borderRadius: 5,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          },
        },
      },
      themeToggler,
    })
  )

export default getTheme
