import { COOKIE_BACK_REDIRECTION, STORAGE_FAKE_REDIRECT_FREQUENCY, STORAGE_LAST_LINK_REDIRECTION_TIME } from '~/lib/cookies'
import { DEFAULT_FAKE_REDIRECTION_FREQUENCY } from '~/pages/api/remoteConfig'
import cookieCutter from 'cookie-cutter'


/**
 * Scroll to reference object
 */
export const scrollToRef = (ref) => {
    if (ref && ref.current /* + other conditions */) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
}

/**
 * Check if two arrays are equal
 */
export function arraysEqual(a: any[], b: any[]): boolean {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false
    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false
    }
    return true
}

/**
 * Escape characters from string that would otherwise have an effect on a regex expression
 */
export function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function clone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj))
}

/**
 * Bypass NextJS page props requiring undefines to be filtered out.
 * This is not necessary in production builds (unless it's a staticly built page)
 */
export function removeUndefinesFromPageProps(obj: object, checkEnv: boolean = false): object {
    if (checkEnv && process.env.NODE_ENV !== 'development') {
        return obj
    }
    return clone(obj)
}

/**
 * Check if window storage type is available
 */
export function isStorageAvailable(type: 'localStorage' | 'sessionStorage') {
    let storage
    try {
        storage = window[type]
        const x = '__storage_test__'
        storage.setItem(x, x)
        storage.removeItem(x)
        return true
    } catch (e) {
        return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0)
    }
}

/**
 * Fake redirection check for back redirection+setup and popunder
 * @param forBackRedirectionSetup Setup back redirection
 * @param ignoreRedirectFrequency I
 */
export function canFakeRedirect(forBackRedirectionSetup: boolean, ignoreRedirectFrequency: boolean = false): boolean {
    if (forBackRedirectionSetup) {
        // setup should check cookie for middleware validation of bot/devicetype
        // setup doesnt care about last redirect time since it's possible user can remain
        // on page after expiry and we should redirect again.
        return cookieCutter.get(COOKIE_BACK_REDIRECTION) === 'true'
    } else if (!ignoreRedirectFrequency) {
        let lastLinkTime: number|undefined = undefined
        let fakeRedirectionFrequency: number = DEFAULT_FAKE_REDIRECTION_FREQUENCY

        // read from local storage last time an affiliate link was clicked or fake redirected. We dont want to do this often
        if (isStorageAvailable('localStorage')) {
            const storedLastLinkTime = Number(localStorage.getItem(STORAGE_LAST_LINK_REDIRECTION_TIME))
            if (storedLastLinkTime && !isNaN(storedLastLinkTime)) {
                lastLinkTime = storedLastLinkTime
            }
            const storedFakeRedirectionFrequency = Number(localStorage.getItem(STORAGE_FAKE_REDIRECT_FREQUENCY))
            if (storedFakeRedirectionFrequency && !isNaN(storedFakeRedirectionFrequency)) {
                fakeRedirectionFrequency = storedFakeRedirectionFrequency
            }
        } else {
            // no storage, dont redirect
            return false
        }

        const curTimeMs = +new Date()
        // non setup should be time/frequency based if we should actually redirect right this second
        return !lastLinkTime || curTimeMs > lastLinkTime + fakeRedirectionFrequency
    }

    return true
}

export function updateLastLinkRedirectionTimeInStorage() {
    if (isStorageAvailable('localStorage')) {
        localStorage.setItem(STORAGE_LAST_LINK_REDIRECTION_TIME, String(+new Date()))
    }
}